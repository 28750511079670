//import logo from './logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import TopLevel from './TopLevel';
//import forceIcon from '../public/pix/force_icon.png';

function App() {
  return (
    <div className="App">
      <TopLevel />
    </div>
  );
}

export default App;
